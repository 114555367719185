<template src="./switch-barclays.html"></template>
<style src="../switch-account/switch-account.scss" lang="scss" scoped></style>
<style src="./switch-barclays.scss" lang="scss" scoped></style>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import deepcopy from 'deepcopy'
import selectPreferenceForm from './select-preference.form'
import basicInfoForm from './basic-info.form'
import companyInfoForm from './company-info.form'
import contactInfoForm from './contact-info.form'
import reviewForm from './review.form'
import ProgressBubbles from '@/components/ProgressBubbles'
import DynamicForm from '@/components/forms/DynamicForm'
import FieldBuilder from '@/components/forms/FieldBuilder'
import { getCompany, patchCompanyInfo, getCompanyOwners, patchCompanyOwners } from '@/api/company'
import { createApplication, updateApplication, updateConsent, submitApplication, getStatus } from '@/api/barclays'
import { prefillFormData, prepareFormData, getStepProgress } from '@/utils/formHelpers'
import { SOLE_TRADER_COMPANY_NUMBER } from '@/utils/constants'
import { getUser, updateUserContactInfo } from '@/api/user'
import ProgressBar from '@/components/ProgressBar'
import { toastDefaultOptions } from '@/config/vue-toast'

export default {
  name: 'SwitchBarclays',
  components: {
    ProgressBubbles,
    DynamicForm,
    ProgressBar,
    FieldBuilder
  },
  data() {
    return {
      progressSteps: ['Before you switch', 'Getting ready', 'Company Information', 'Contact Information', 'Review'],
      currentStep: 0,
      currentForm: null,
      selectPreferenceForm: deepcopy(selectPreferenceForm),
      preferencePromise: null,
      preference: null,
      basicInfoForm: deepcopy(basicInfoForm),
      basicInfoPromise: null,
      basicInfo: {},
      companyInfoForm: deepcopy(companyInfoForm),
      companyInfoPromise: null,
      companyInfo: {},
      companyOwners: null,
      contactInfoForm: deepcopy(contactInfoForm),
      contactInfoPromise: null,
      contactInfo: {},
      reviewForm: deepcopy(reviewForm),
      reviewSubmitPromise: null,
      review: {},
      loading: {},
      applicationId: null,
      applicationSubmited: false,
      applicationError: false,
      user: null,
      progressBarValue: null,
      stepTitle: null,
      currentStepNumber: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    currentProgress() {
      return this.currentForm ? getStepProgress(this[`${this.currentForm}`]) : { total: 0, completed: 0 }
    },
    currentProgressValue() {
      const steps = 6
      const stepRange = 100 / steps
      let progressBarCompleted = 0
      const questionFormRange = stepRange / (this.currentProgress.total + 1)
      progressBarCompleted = this.progressBarValue + questionFormRange * this.currentProgress.completed
      return { stepRange, questionFormRange, progressBarCompleted }
    },
    bubbleStep() {
      if (this.currentStep === 1) {
        return 1
      } else if (this.currentStep < 5) {
        return 2
      } else {
        return 3
      }
    },
    isOwnersFilled() {
      return this.companyOwners && !!Object.keys(this.companyOwners).length
    }
  },
  methods: {
    async moveToStep(n) {
      switch (n) {
        case 1:
          this.currentForm = 'selectPreferenceForm'
          this.progressBarValue = 5
          this.currentStep = 1
          this.currentStepNumber = 1
          this.stepTitle = 'Before you switch'
          break
        case 2:
          this.currentForm = 'basicInfoForm'
          this.progressBarValue = 20
          this.currentStep = 2
          this.currentStepNumber = 2
          this.stepTitle = 'Getting ready'
          break
        case 3:
          this.currentForm = 'companyInfoForm'
          this.progressBarValue = 40
          this.currentStep = 3
          this.currentStepNumber = 3
          this.stepTitle = 'Company Information'
          this.$set(this.loading, 'step3', true)
          await this.fillCompanyInfo()
          await this.fillCompanyOwners()
          this.loading.step3 = false
          break
        case 4:
          this.currentForm = 'contactInfoForm'
          this.progressBarValue = 60
          this.currentStep = 4
          this.currentStepNumber = 4
          this.stepTitle = 'Contact Information'
          this.$set(this.loading, 'step4', true)
          await this.fillContactInfo()
          this.loading.step4 = false
          break
        case 5:
          this.currentForm = 'reviewForm'
          this.progressBarValue = 90
          this.currentStep = 5
          this.currentStepNumber = 5
          this.stepTitle = 'Review'
          break
        case 6:
          this.currentForm = null
          this.progressBarValue = 100
          this.currentStep = 6
          this.stepTitle = 'Application submitted'
          break
      }
    },
    fillCompanyInfo() {
      return getCompany(this.companyId)
        .then(response => response.data)
        .then(data => {
          prefillFormData(this.companyInfoForm, {
            ...data,
            companyStructures: !data.companyStructures && data.companyNumber === SOLE_TRADER_COMPANY_NUMBER ? 'Unlimited Company (Sole Trader)' : data.companyStructures
          })
          this.companyInfo = prepareFormData(this.companyInfoForm).data
        })
        .catch(e => {
          this.loading.step3 = false
        })
    },
    fillCompanyOwners() {
      return getCompanyOwners(this.companyId)
        .then(response => response.data[0])
        .then(owners => {
          prefillFormData(this.companyInfoForm, owners)
          this.companyOwners = owners || {}
        })
        .catch(e => {
          this.loading.step3 = false
        })
    },
    fillContactInfo() {
      return getUser()
        .then(response => response.data)
        .then(contact => {
          this.user = contact
          prefillFormData(this.contactInfoForm, { ...contact, isInUK: contact.countryOfResidence === 'United Kingdom' })
          this.contactInfo = prepareFormData(this.contactInfoForm).data
        })
        .catch(e => {
          this.loading.step4 = false
        })
    },
    submitPreference(formData) {
      this.preference = formData.data.preference
      this.preferencePromise = createApplication({
        companyId: this.companyId,
        eligibilityAndPreference: this.preference
      }).then(response => {
        this.applicationId = response.data
        this.moveToStep(2)
      })
      return this.preferencePromise
    },
    submitBasicInfo(formData) {
      this.basicInfo = formData.data
      this.basicInfoPromise = updateApplication({ id: this.applicationId, ...this.basicInfo }).then(() => {
        this.moveToStep(3)
      })
      return this.basicInfoPromise
    },
    async submitCompanyInfo(formData) {
      // If something has changed, patch it
      // submit chanes for tep Confirm Contact Information ( iclude company info, company owners )
      const companyInfoFields = { ...this.companyInfo, ...this.companyOwners }
      if (JSON.stringify(companyInfoFields) !== JSON.stringify(formData.data)) {
        const { companyName, companyNumber = SOLE_TRADER_COMPANY_NUMBER, registeredAddressCountry, registeredAddressPostcode, incorporationDate, ...ownersUpadted } = formData.data
        const owners = [{ ...this.companyOwners, ...ownersUpadted }]
        this.companyInfoPromise = patchCompanyOwners({ companyId: this.companyId, owners })
          .then(() => {
            return patchCompanyInfo({ companyId: this.companyId, companyName, companyNumber, registeredAddressCountry, incorporationDate, registeredAddressPostcode })
          })
          .then(() => {
            this.moveToStep(4)
          })
        return this.companyInfoPromise
      } else {
        this.companyInfoPromise = null
        this.moveToStep(4)
      }
    },
    submitContactInfo(formData) {
      // If something has changed, patch it
      if (JSON.stringify(this.contactInfo) !== JSON.stringify(formData.data)) {
        this.contactInfo = formData.data
        this.contactInfoPromise = updateUserContactInfo({
          postCode: this.companyInfo.registeredAddressPostcode,
          countryOfResidence: this.user.countryOfResidence || 'N/A',
          ...this.contactInfo
        }).then(() => {
          this.moveToStep(5)
        })
        return this.contactInfoPromise
      } else {
        this.contactInfoPromise = null
        this.moveToStep(5)
      }
    },
    async submitReview(formData) {
      this.review = formData.data

      try {
        this.reviewSubmitPromise = Promise.all([await updateConsent({ id: this.applicationId, ...this.review }), await this.submitApplication(), await this.getApplicationStatus()])

        this.applicationSubmited = true
        this.applicationError = false
        this.moveToStep(6)
      } catch (e) {
        this.$toasted.clear()
        const message = `Something went wrong, we've sent a report to our technical support team! Please try again shortly, and if the issue persists, someone will get in touch soon to
                    assist in completing this application.`
        this.$toasted.show(message, { ...toastDefaultOptions, type: 'error', icon: 'error', duration: 6000 })
        this.applicationSubmited = false
        this.applicationError = true
      }
    },
    submitApplication() {
      return submitApplication({ id: this.applicationId })
    },
    getApplicationStatus() {
      return getStatus(this.applicationId)
    },
    handleSubmitResultMainButtonClick() {
      if (this.applicationSubmited) {
        this.$router.push({ name: 'dashboard' })
      } else {
        this.moveToStep(5)
      }
      this.applicationSubmited = false
      this.applicationError = false
    }
  },
  created() {
    this.moveToStep(1)
    prefillFormData(this.reviewForm, { isUserAccountant: false })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';
/deep/ {
  .progress-bar {
    background: var(--color-primary-100);
    border: 1px solid #ffffff;
    border-radius: 4px;
    height: 10px;
    margin: 0rem;
    .progress-bar-value {
      background: var(--color-tertiary-400);
      border-radius: 4px;
      height: 8px;
    }
  }
  .btn-primary {
    background: var(--color-tertiary-400);
  }

  .btn-secondary {
    margin-right: 10px;
  }

  .select-preference-form {
    .field-wrapper {
      margin-bottom: 23px;
      padding-bottom: 33px;
      padding-left: 0;
      border-bottom: 1px solid var(--color-primary-100);
    }
    select {
      max-width: 378px;
    }

    .error {
      top: 72%;
    }
  }

  .content-submitted {
    margin-bottom: 23px;
    padding-bottom: 33px;
    border-bottom: 1px solid var(--color-primary-100);
  }

  .input-radio:checked + .holder .icon[data-v-6115669b] {
    color: var(--color-tertiary-400);
  }

  .checkbox.is-checked .icon[data-v-33525b5a] {
    color: var(--color-tertiary-400);
  }

  .basic-info-form {
    .form-group-content {
      border-bottom: 1px solid var(--color-primary-100);
      margin-bottom: 23px;
      padding-bottom: 30px;
    }

    .field-wrapper {
      margin-bottom: 14px;
    }
  }

  .contact-info-form {
    .form-group-content {
      border-bottom: 1px solid var(--color-primary-100);
      margin-bottom: 23px;
      padding-bottom: 30px;
    }
  }

  .review-form {
    .form-group-content {
      border-bottom: 1px solid var(--color-primary-100);
      margin-bottom: 23px;
      padding-bottom: 30px;
    }
  }

  .error {
    top: 98%;
    font-size: 12px;
  }

  .company-info-form {
    .form-group-content {
      border-bottom: 1px solid var(--color-primary-100);
      margin-bottom: 23px;
      padding-bottom: 30px;
    }
  }

  .step-title {
    font-size: 12px;
    margin-bottom: 5px;
  }

  input,
  select {
    color: var(--color-primary-400);
    margin-top: 7px;
  }

  .vdp-datepicker {
    margin-top: 7px;
  }

  h1 {
    font-weight: normal;
  }

  .spinner-container {
    .spinner {
      margin: 0 auto;
      display: block;
      margin-top: 50px;
    }
  }

  .ui {
    .action-container {
      button,
      .button {
        padding: 0.75rem 1.5em;
        margin-top: 1rem;
      }
      .back.circular.button {
        @media only screen and (max-width: $breakpoint-sm) {
          width: calc(100% - 5rem);
        }
      }
    }
    .field > .selection.dropdown {
      margin-top: 3px;
    }
    .form-submit-btn {
      background: var(--color-tertiary-400);
    }
  }
}
</style>
