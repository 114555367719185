export default {
  name: 'basicInfoForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'isNewAccount',
            veeAs: ' ',
            label: 'Please indicate if you would like to open a new account, or switch from existing?',
            placeholder: 'Select an option',
            rules: 'required',
            options: [
              {
                value: true,
                label: 'Open a new account'
              },
              {
                value: false,
                label: 'Switch from existing'
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            name: 'withPinsentry',
            veeAs: ' ',
            label: 'Are you an existing Barclays Customer with Pinsentry?',
            popover: 'If you are, this will enable a fast track switching journey with the Barclays team.',
            placeholder: '',
            rules: 'required',
            options: [
              {
                value: true,
                label: 'Yes'
              },
              {
                value: false,
                label: 'No'
              }
            ],
            isInline: true,
            selected: ''
          }
        ]
      ]
    }
  ]
}
