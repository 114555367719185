
<template>
<div class="progress">
  <template v-for="(step, i) in steps">
    <div class="line" :class="{ active: i < value }" v-if="i" :key="`line-${i}`"></div>
    <div class="step" :class="{ active: i < value }" :key="`step-${i}`">
      <div class="label-text">{{ step }}</div>
    </div>
  </template>
</div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

$bg: #EDEEEE;
$label-text-lh: 24px;
.progress {
  $bg-active: $color-main;
  $step-size: 48px;
  $step-border-width: 5px;
  $line-size: 10px;
  $line-border-width: 4px;
  $side-gap: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: calc(#{$label-text-lh});
  .step,
  .line {
    box-sizing: content-box;
    background-color: $bg;
    &.active {
      background-color: $bg-active;
      transition: background-color ease-in-out 0.3s;
      .label-text {
        opacity: 1;
        transition: opacity ease-in-out 0.3s;
      }
    }
  }
  .step {
    width: $step-size;
    height: $step-size;
    border-radius: 50%;
    border: #{$step-border-width} solid #{$bg};
    flex-shrink: 0;
    position: relative;
    &:first-child {
      margin-left: $side-gap;
    }
    &:last-child {
      margin-right: $side-gap;
    }
    .label-text {
      position: absolute;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      top: calc(100% + #{$step-border-width});
      left: 50%;
      transform: translateX(-50%);
      max-width: calc(#{$step-size} + 2 * #{$step-border-width} + 2 * #{$side-gap});
      font-size: 16px;
      font-weight: 500;
      line-height: $label-text-lh;
      color: $color-main;
      opacity: 0.5;
    }
  }
  .line {
    flex-grow: 1;
    height: $line-size;
    border-top: #{$line-border-width} solid #{$bg};
    border-bottom: #{$line-border-width} solid #{$bg};
    margin: 0 -#{$step-border-width};
    z-index: 1;
  }
}
@media only screen and (max-width: $breakpoint-sm-max) {
  .progress {
    $step-size: 32px;
    $step-border-width: 3px;
    $line-size: 8px;
    $line-border-width: 2px;
    $side-gap: 24px;
    .step {
      width: $step-size;
      height: $step-size;
      border: #{$step-border-width} solid #{$bg};
      &:first-child {
        margin-left: $side-gap;
      }
      &:last-child {
        margin-right: $side-gap;
      }
      .label-text {
        top: calc(100% + #{$step-border-width});
        max-width: calc(#{$step-size} + 2 * #{$step-border-width} + 2 * #{$side-gap});
        line-height: $label-text-lh;
        color: $color-main;
      }
    }
    .line {
      height: $line-size;
      border-top: #{$line-border-width} solid #{$bg};
      border-bottom: #{$line-border-width} solid #{$bg};
      margin: 0 -#{$step-border-width};
    }
  }
}
</style>
