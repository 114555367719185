export default {
  name: 'reviewForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'checkbox',
            name: 'hasNoPreviousSubmissions',
            veeAs: ' ',
            label: 'I Hereby confirm no other previous submissions for Barclays business account switching were done by myself or on my behalf.',
            placeholder: '',
            rules: 'required',
            selected: false
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'checkbox',
            name: 'dataShareConsent',
            veeAs: ' ',
            label: `I Hereby provide consent for the personal data, collected by Swoop, to be shared with the external party, Barclays Bank
            Account Switching team, as part of this application by Swoop Funding Limited on your behalf.`,
            placeholder: '',
            rules: 'required',
            selected: false,
            dependsOn: [
              {
                fieldName: 'isUserAccountant',
                values: [false]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'checkbox',
            name: 'dataShareConsent',
            veeAs: ' ',
            label: `I Hereby confirm that I have gained explicit consent for my client's data, provided by me and my client in Swoop application,
            to be shared with the external party, Barclays Bank Account Switching team, as part of this application by Swoop Funding
            Limited on my client's behalf.`,
            placeholder: '',
            rules: 'required',
            selected: false,
            dependsOn: [
              {
                fieldName: 'isUserAccountant',
                values: [true]
              }
            ]
          }
        ],

        [
          {
            field: 'dummy',
            name: 'isUserAccountant'
          }
        ]
      ]
    }
  ]
}
