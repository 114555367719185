export default {
  name: 'contactInfoForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'firstName',
          veeAs: ' ',
          label: 'First Name',
          placeholder: '',
          rules: 'required',
          selected: ''
        }, {
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'lastName',
          veeAs: ' ',
          label: 'Last Name',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'email',
          veeAs: ' ',
          label: 'Email',
          placeholder: '',
          rules: 'required',
          selected: ''
        },
        {
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'contactNumber',
          veeAs: ' ',
          label: 'Contact Number',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          name: 'isInUK',
          veeAs: ' ',
          label: 'Country of residence',
          placeholder: '',
          rules: 'required',
          options: [
            {
              value: true,
              label: 'UK'
            },
            {
              value: false,
              label: 'Non-UK'
            }
          ],
          isInline: true,
          selected: ''
        }]
      ]
    }
  ]
}
