export default {
  name: 'selectPreferenceForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'preference',
            veeAs: ' ',
            label: 'Please indicate your eligibility and preference:',
            placeholder: 'Select your preference',
            rules: 'required',
            options: [
              {
                value: 'OnlineJourney',
                label: 'Online Journey'
              },
              {
                value: 'WithBranchVisit',
                label: 'With Branch Visit'
              }
            ],
            selected: ''
          }
        ]
      ]
    }
  ]
}
