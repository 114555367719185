import config from '@/config/global'
import dictionary from '@/config/dictionary'
import { capitalizeFirstLetter } from '@/utils/utils'
import { countriesOptions } from '@/utils/countries'
import { SOLE_TRADER_COMPANY_NUMBER } from '@/utils/constants'

export default {
  name: 'companyInfoForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: 'Company Information',
      name: 'company',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'companyNumber',
            veeAs: ' ',
            label: capitalizeFirstLetter(dictionary.companyNumber),
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'companyNumber',
                rule(selected) {
                  return selected !== SOLE_TRADER_COMPANY_NUMBER
                }
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'companyName',
            veeAs: ' ',
            label: 'Company Name',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            isInline: false,
            name: 'registeredAddressCountry',
            veeAs: ' ',
            label: 'Country of Incorporation',
            placeholder: '',
            rules: 'required',
            columns: 2,
            options: countriesOptions,
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'registeredAddressPostcode',
            veeAs: ' ',
            label: 'Registered Office Address Post Code',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker',
            type: '',
            name: 'incorporationDate',
            veeAs: ' ',
            label: 'Incorporation Date',
            placeholder: '',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          }
        ]
      ]
    },
    {
      title: 'Company Owner details',
      name: 'owners',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'firstName',
            veeAs: 'first name',
            label: 'Company Owner First Name',
            placeholder: '',
            rules: 'required|name',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'lastName',
            veeAs: 'last name',
            label: 'Company Owner Last Name',
            placeholder: '',
            rules: 'required|name',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'gender',
            veeAs: 'gender',
            label: 'Gender',
            placeholder: 'Not specified',
            rules: 'required',
            options: [
              {
                label: 'Male',
                value: 'Male'
              },
              {
                label: 'Female',
                value: 'Female'
              },
              {
                label: 'Prefer not to say',
                value: 'PreferNotToSay'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'employmentStatus',
            veeAs: 'employment status',
            label: 'Employment status',
            placeholder: 'Not specified',
            rules: 'required',
            options: [
              {
                label: 'Not Found',
                value: 'NotFound'
              },
              {
                label: 'Employed',
                value: 'Employed'
              },
              {
                label: 'Self Employed',
                value: 'SelfEmployed'
              },
              {
                label: 'Retired',
                value: 'Retired'
              },
              {
                label: 'Not Provided',
                value: 'NotProvided'
              },
              {
                label: 'Other',
                value: 'Other'
              },
              {
                label: 'Unemployed',
                value: 'Unemployed'
              },
              {
                label: 'Full Time',
                value: 'FullTime'
              },
              {
                label: 'Part Time',
                value: 'PartTime'
              },
              {
                label: 'Self Employed Less Than 2 Years',
                value: 'SelfEmployedLessThan2Years'
              },
              {
                label: 'Self Employed More Than 2 Years',
                value: 'SelfEmployedMoreThan2Years'
              },
              {
                label: 'Student',
                value: 'Student'
              },
              {
                label: 'Homemaker',
                value: 'Homemaker'
              },
              {
                label: 'Temporarily Employed',
                value: 'TemporarilyEmployed'
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker',
            type: '',
            name: 'dateOfBirth',
            veeAs: 'date of birth',
            label: 'Company Owner Date of Birth',
            placeholder: '',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            selected: '',
            disabledDates: { from: new Date(new Date().setDate(new Date().getDate() - 1)) }
          },
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'income',
            veeAs: 'annual income',
            label: 'Annual income',
            placeholder: '0',
            rules: 'required|decimal|min_value:0',
            icon: {
              text: config.whitelabel.country.currency.defaultCurrencySymbol,
              position: 'left'
            },
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'country',
            veeAs: 'country',
            label: 'Country',
            placeholder: 'Select a country',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'city',
            veeAs: 'city / town',
            label: 'City / Town',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'buildingNumber',
            veeAs: 'building number',
            label: 'Company Owner Residential building number',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'street',
            veeAs: 'street',
            label: 'Company Owner Residential street name',
            placeholder: '',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'postcode',
            veeAs: 'postcode',
            label: 'Company Owner Residential post code',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'residentialStatus',
            veeAs: 'residential status',
            label: 'Residential status',
            placeholder: 'Select a status',
            rules: 'required',
            options: [
              {
                label: 'Not Found',
                value: 'NotFound'
              },
              {
                label: 'Home Owner',
                value: 'HomeOwner'
              },
              {
                label: 'Living With Parents',
                value: 'LivingWithParents'
              },
              {
                label: 'Tenant',
                value: 'Tenant'
              },
              {
                label: 'Not Provided',
                value: 'NotProvided'
              },
              {
                label: 'Other',
                value: 'Other'
              },
              {
                label: 'Private Tenant',
                value: 'PrivateTenant'
              },
              {
                label: 'Council Tenant',
                value: 'CouncilTenant'
              },
              {
                label: 'Home Owner No Mortgage',
                value: 'HomeOwnerNoMortgage'
              }
            ],
            selected: ''
          }
        ]
      ]
    }
  ]
}
